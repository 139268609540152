import { ChangeEvent, useMemo, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner, Stack } from "react-bootstrap"
import { FieldName } from "../../interfaces/fields.interface";
import { useFieldValues } from "../../hooks/useFieldValues";
import { authClient } from "../../api-clients/AuthClient";
import { AssetType, FieldValue } from "../../types";
import { AssetContext } from "../../hooks/useAssetGridData";
import { ToastContainer, toast } from "react-toastify";

interface InviteUserParams {
    onHide: () => void
}

export function InviteUserModal({ onHide }: InviteUserParams) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [email, setEmail] = useState("");
    const [selectedCompany, setSelectedCompany] = useState<FieldValue | null>(null);
    const filterOptions = "" && FieldName.Company ? {  } : {};
    const fieldValuesResponse = useFieldValues(FieldName.Company, filterOptions);
    const assetContext = useMemo<AssetContext>(() => ({
        assetType: AssetType.Customer,
    }), []);

    const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
    };

    const onGroupSelected = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedField = fieldValuesResponse.data?.fieldValues.find((item) => item.value.toString() === e.target.value);
        setSelectedCompany(selectedField || null);
    };

    async function onSend() {
        const newCustomer = {
            UserEmail: email,
            CustomerID: selectedCompany?.value.toString()
        }
        setLoading(true);
        try {
            await authClient.createUser(newCustomer, assetContext);
        }
        finally {
            setLoading(false);
            toast.success("Invite Sent!");
            setEmail("");
        }
    }

    return (
        <Modal style={{minWidth: 300}} show={true} onHide={onHide}>
            <ToastContainer position="top-center"/>
            <Modal.Header closeButton>
                <Modal.Title>Invite New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack direction="vertical" gap={3}>
                    <Stack direction="vertical">
                        <Form.Label>Company</Form.Label>
                        <Form.Select size="sm" value={selectedCompany ? selectedCompany.value : ''} onChange={onGroupSelected}>
                            <option value=""></option>
                            {fieldValuesResponse.data?.fieldValues.sort((a, b) => a.displayValue.localeCompare(b.displayValue)).map((item) => (
                                <option key={item.value} value={item.value}>
                                    {item.displayValue}
                                </option>
                            ))}
                        </Form.Select>
                    </Stack>
                    <Stack direction="vertical">
                        <Form.Label>Email</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" size="sm" value={email ?? ""} onChange={onEmailChange} required={true} />
                        </InputGroup>
                    </Stack>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                {isLoading ? <Spinner/> :
                    <Button variant="primary" onClick={onSend}>Send</Button>
                }
            </Modal.Footer>
        </Modal>
    )
}