import { Stack, InputGroup, Alert, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { ChangeEvent, useState } from 'react';
import { toast } from 'react-toastify';
import { authClient } from '../../api-clients/AuthClient';
import { Customer, MOUCompany, UpdateResponse } from '../../pages/admin-settings/AdminSettingsPage';

interface CredentialParams {
    currentUser: Customer,
    company: MOUCompany | null,
    onHide: (success: boolean) => void
}

export function AddCredentialModal({ currentUser, company, onHide }: CredentialParams) {
    const [newCompanyName, setCompanyName] = useState<string>(company?.mouCompanyName ?? "");
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isLoading, setLoading] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    
    const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    };

    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    };

    const onCompanyNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setCompanyName(event.target.value);
    }

    async function setCredentials(username: string, password: string, companyName: string) {
        setHasError(false);
        setErrorMessage("");
        if (currentUser === null || currentUser === undefined) {
            toast.error("Error Occurred. Please try logging in again.");
            return;
        }
        if (username === "" || password === "") {
            toast.error("Please enter username/password");
            return;
        }
        const webServiceCredentials = {
            username: username,
            password: password,
            companyName: companyName
        }
        setLoading(true);
        let companyId = company?.mouCompanyID?.toString() ?? "0";
        var response = await authClient.updateCustomer<UpdateResponse>(webServiceCredentials, currentUser.customerId, companyId);
        var parsedResult = JSON.parse(JSON.stringify(response));
        if (parsedResult.uscisAuthenticated) {
            toast.success("Success!");
            setCompanyName("");
            onHide(true);
        }
        else {
            const error = `${parsedResult.error ?? ""}.\nPlease confirm you account is a valid USCIS web service account and that your credentials are entered correctly.`
            setHasError(true);
            setErrorMessage(error);

        }
        setLoading(false);
    }

  return (
    <Modal style={{minWidth: 500}} show={true} onHide={() => onHide(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add USCIS Credentials</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form style={{ marginTop: 16 }}>
                <h6>Please enter your E-Verify web service credentials below.</h6>
                <Stack direction="vertical" style={{ width: 400 }} gap={3}>
                    <Stack direction="vertical">
                        <Form.Label>Company Name</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" size="sm" value={newCompanyName ?? ""} onChange={onCompanyNameChange} required={true} />
                        </InputGroup>
                    </Stack>
                    <Stack direction="vertical">
                        <Form.Label>Web Service Username</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" size="sm" value={username ?? ""} onChange={onUsernameChange} required={true} />
                        </InputGroup>
                    </Stack>
                    <Stack direction="vertical">
                        <Form.Label>Web Service Password</Form.Label>
                        <InputGroup>
                            <Form.Control type="password" value={password ?? ""} onChange={onPasswordChange} required={true} />
                        </InputGroup>
                    </Stack>
                    
                </Stack>
            </Form>
        </Modal.Body>
        <Modal.Footer>
        {isLoading ? 
            <Spinner/> 
            :   
            <>
                <Button size="sm" variant="primary" style={{ width: 150 }}
                        onClick={() => {
                            setCredentials(username, password, newCompanyName);
                        } }>Update Credentials
                </Button>
                <Alert style={{ marginTop: 16 }} show={hasError} variant="danger">{errorMessage}</Alert>
            </>
        }
        </Modal.Footer>
    </Modal>
  );
}