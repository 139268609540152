import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getLogContext, LogContext, useLogger } from "../hooks/useLogger"
import { isDevMode } from "../logic/tools"

const logGroup = "ErrorDetails";
const errorMessage = "An error occurred";

type MapStackTraceFn = (stackTrace:string, callback:(mappedStackTrace:string[])=>void) => void;

export function ErrorDetails({ message, error }: { message: string, error: Error }) {
    const logger = useLogger();
    logger.error(logGroup, errorMessage, error);
    return <div>
        <h1>{ message }</h1>
        { isDevMode() && <DebugDetails error={error} /> }
    </div>
}

function DebugDetails({ error }: { error: Error }) {
    const { mappedStackTrace, isLoadingMappedStackTrace } = useMappedStackTrace(error.stack);
    const logContext:LogContext = getLogContext(logGroup, errorMessage, error);
    return (
        <div>
            { logContext.statusCode === 502 && logContext.response?.error === 'local server is not up' && <CommonLocalServerNotRunning /> }
            { logContext.logGroup && <pre>Log Group: {logContext.logGroup}</pre> }
            <pre>Message: {error.message}</pre>
            <pre>Stack:
                { isLoadingMappedStackTrace && <>
                    <span>...Loading</span>
                    <div>
                        <Spinner />
                    </div> 
                </>}
                { !isLoadingMappedStackTrace && mappedStackTrace }
            </pre>
            <pre>Context: {JSON.stringify(logContext, null, 3)}</pre>
        </div>
    );
}

function CommonLocalServerNotRunning() {
    return <h2>Nginx is responding with an error because ascend-inventory-api is not running</h2>
}

function useMappedStackTrace(stackTrace:string|undefined) {
    const [mappedStackTrace, setMappedStackTrace] = useState(stackTrace);
    const [isLoadingMappedStackTrace, setIsLoadingMappedStackTrace] = useState(true);

    useEffect(() => {
        const windowAny = window as any;
        const mapStackTrace:MapStackTraceFn|undefined = windowAny?.mapStackTrace ?? windowAny?.sourceMappedStackTrace?.mapStackTrace;
        if (isDevMode() && mapStackTrace !== undefined && stackTrace) {
            setIsLoadingMappedStackTrace(true);
            mapStackTrace(stackTrace, (mappedStackTrace:string[]) => {
                setMappedStackTrace(mappedStackTrace.join('\n').trim());
                setIsLoadingMappedStackTrace(false);
            });
        } else {
            setIsLoadingMappedStackTrace(false);
        }
    }, [stackTrace]);

    return { mappedStackTrace, isLoadingMappedStackTrace };
}
