import { Outlet } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import Navigation from "../components/Navigation";

export default function AppLayout() {
    return (
        <div className="flex-vertical fullHeight">
            <Navigation />
            <div className="pageContent flex-fill flex-vertical">
                <ErrorBoundary>
                    <div className="flex-fill">
                        <Outlet />
                    </div>
                </ErrorBoundary>
            </div>
        </div>
    );
}
