import { useEffect, useState } from "react";
import { Button, Spinner, Stack, Table } from "react-bootstrap";
import { InviteUserModal } from "../../components/Modals/InviteUserModal";
import { authClient } from "../../api-clients/AuthClient";
import { ToastContainer, toast } from "react-toastify";
import { loginRequest } from "../../authConfig";
import { AuthenticatedTemplate, useMsal } from "@azure/msal-react";
import PageLayout from "../PageLayout";
import ErrorBoundary from "../../components/ErrorBoundary";
import "react-toastify/dist/ReactToastify.css";
import { GenerateKeyModal } from "../../components/Modals/GenerateKeyModal";
import useLocalStorage from "use-local-storage";
import { ConfirmationModal } from "../../components/Modals/ConfirmationModal";
import { AddCredentialModal } from "../../components/Modals/AddCredentialModal";

export interface Customer {
    customerId: string,
    email: string,
    isInternalUser: boolean
}

export interface MOUCompany {
    mouCompanyID: number,
    mouCompanyName: string
}

export interface UpdateResponse {
    error: string | null,
    uscisAuthenticated: boolean
}

interface User {
    customerName: string,
    email: string,
    mouCompanyCount: number,
    dateAdded: Date,
}

interface CustomerEntitlement {
    customerName: string,
    accessDoc: string,
    entitlementSecret: string,
    customerID: string,
    version: string
}

export default function AdminSettingsPage() {
    const { instance, accounts } = useMsal();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [, setLocalUserSub] = useLocalStorage("userSub", "");
    const [, setLocalUserEmail] = useLocalStorage("userEmail", "");
    
    const [currentUser, setCurrentUser] = useState<Customer>({
        customerId: "",
        email: "",
        isInternalUser: false
    });
    const [companies, setCompanies] = useState<MOUCompany[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [customerEntitlements, setCustomerEntitlements] = useState<CustomerEntitlement[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<MOUCompany | null>(null);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isCustomer, setIsCustomer] = useState<boolean>(false);
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [showNewCustomerModal, setShowNewCustomerModal] = useState(false);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [hasCompanies, setHasCompanies] = useState<boolean>(false);
    const [showCredentialForm, setShowCredentialForm] = useState<boolean>(false);
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [loadingStates, setLoadingStates] = useState<boolean[]>(Array(users.length).fill(false));

    useEffect(() => {
        setIsLoggedIn(accounts.length > 0);
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
            setIsLoggedIn(true);
        }
        if (currentUser !== null && currentUser !== undefined && currentUser.customerId !== "") {
            setIsAdmin(currentUser.isInternalUser);
            if (currentUser.isInternalUser) {
                getUsers();
                getCustomerEntitlements();
            }
        }
    }, [accounts, currentUser, instance]);

    useEffect(() => {
        if (accounts.length > 0) {
            fetchCustomer(accounts[0].username);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleUpdateClick(company: MOUCompany) {
        setSelectedCompany(company as MOUCompany);
        setShowCredentialForm(true);
    }

    async function handleRemoveClick(company: MOUCompany) {
        setSelectedCompany(company as MOUCompany);
        setShowConfirmation(true);
    }

    function handleAddMoreClick() {
        setSelectedCompany(null);
        setShowCredentialForm(true);
    }

    async function handleValidation(user: CustomerEntitlement, index: number) {
        const newLoadingStates = [...loadingStates];
        newLoadingStates[index] = true;
        setLoadingStates(newLoadingStates);
        try {
            const credentials = {
                token: user.entitlementSecret
            }
            var response = await authClient.validateCustomer<UpdateResponse>(credentials, user.customerID);
            var parsedResult = JSON.parse(JSON.stringify(response));
            if (parsedResult.uscisAuthenticated) {
                toast.success("Customer Valid!");
            }
            else {
                toast.error("Customer Invalid!");
            }
        }
        catch(error) {
            console.error('Error fetching data:', error);
        }
        finally {
            newLoadingStates[index] = false;
            setLoadingStates(newLoadingStates);
            getCustomerEntitlements();
        }
        
    }

    async function getUsers() {
        try {
            setLoading(true);
            const response = await authClient.getInvitedUsers<User[]>();
            if (response !== undefined || response !== null) {
                setUsers(response.data);
            }
            else {
                setUsers([]);
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error);
        }
        finally {
            setLoading(false);
        }
    }

    async function getCustomerEntitlements() {
        try {
            setLoading(true);
            const response = await authClient.getCustomerEntitlements<CustomerEntitlement[]>();
            if (response !== undefined || response !== null) {
                setCustomerEntitlements(response.data);
            }
            else {
                setCustomerEntitlements([]);
            }
        } 
        catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    }

    async function fetchCustomer(email: string) {
        try {
            setLoading(true);
            const response = await authClient.getCustomer<Customer>(email);
            if (response !== undefined || response !== null) {
                setIsCustomer(true);
                setCurrentUser(JSON.parse(JSON.stringify(response.data)));
                toast.success(`${email} Signed In`);
                await fetchMOUCompanies(response.data.customerId);
            }
            else {
                setIsCustomer(false);
                handleLogout();
                toast.error("Unauthorized");
            }
        } 
        catch (error) {
            setIsCustomer(false);
            handleLogout();
            setLoading(false);
            toast.error("Unauthorized");
            console.error('Error fetching data:', error);
        }
        setLoading(false);
    };

    async function fetchMOUCompanies(customerId: string) {
        try {
            const response = await authClient.getMOUCompanies<MOUCompany[]>(customerId);
            if (response !== undefined || response !== null) {
                setCompanies(response.data);
                setHasCompanies(true);
                setShowCredentialForm(false);
            }
            else {
                setCompanies([]);
                setHasCompanies(false);
                setShowCredentialForm(true);
            }
        } 
        catch (error) {
            console.error('Error fetching data:', error);
            setShowCredentialForm(true);
        }
    }

    async function resendInvite(email: string, index: number) {
        const newUsers = [...users];
        const newLoadingStates = [...loadingStates];
        newLoadingStates[index] = true;
        setLoadingStates(newLoadingStates);

        const emailRequest = {
            Email: email
        }

        try {
            await authClient.resendInvite<boolean>(emailRequest);
        } 
        catch (error) {
            console.error('Error resending invite:', error);
        }
        finally {
            newLoadingStates[index] = false;
            setLoadingStates(newLoadingStates);
            setUsers(newUsers);
        }
    }

    const handleLogin = async () => {
        var authResult = await instance.loginPopup({...loginRequest,redirectUri: '/login',})
            .catch((error) => console.log(error));
        if (authResult?.account.idTokenClaims !== undefined)
        {
            setIsLoggedIn(true);
            instance.setActiveAccount(instance.getAllAccounts()[0]);
            setLocalUserSub(authResult?.account.localAccountId);
            setLocalUserEmail(authResult?.account.username);
            await fetchCustomer(authResult?.account.username);
        }
    };

    const handleLogout = async () => {
        instance.logout();
        setIsLoggedIn(false);
    }

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        const month = date.getMonth() + 1;
        const day = date.getDate();
        const year = date.getFullYear();
        
        return `${month}/${day}/${year}`;
    }

    return (
        <PageLayout pageName="Welcome to the Fiddletree Systems Customer Configuration Site">
            <ToastContainer position="bottom-center"/>
            { !isLoggedIn ? 
                <div style={{ display: "flex", justifyContent: "center"}}>
                    <Button size="sm" style={{width: 150, marginTop: 24}} onClick={handleLogin}> Sign In </Button>
                </div>
                : 
                <div></div>
            }
            <AuthenticatedTemplate>
                { isLoading ? 
                    <div style={{ display: "flex", justifyContent: "center"}}>
                        <Spinner/>
                    </div> 
                : 
                <>
                    { isAdmin ? 
                    <>
                        <div style={{ display: "flex", justifyContent: "right" }}>
                            <Button size="sm" style={{ width: 150 }} onClick={handleLogout}> Sign Out </Button>
                        </div>
                        <Stack direction="vertical">
                            <Button size="sm" variant="primary" style={{width: 150}} onClick={() => setShowInviteModal(true)}>Invite New User</Button>
                            <Table style={{ marginTop: 32, fontSize: 14 }} size="sm" bordered hover>
                                <thead>
                                    <tr>
                                        <th>Customer Name</th>
                                        <th>Email</th>
                                        <th>MOU Companies</th>
                                        <th>Date Added</th>
                                        <th>Resend Invite</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.customerName}</td>
                                            <td>{user.email}</td>
                                            <td>{user.mouCompanyCount}</td>
                                            <td>{formatDate(user.dateAdded.toString())}</td>
                                            <td>
                                                { loadingStates[index] ? 
                                                    <Spinner/> 
                                                :
                                                    <Button size="sm" onClick={() => resendInvite(user.email, index)}>Resend Invite</Button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Button size="sm" style={{width: 200, marginTop: 24}} onClick={() => setShowNewCustomerModal(true)}> Create New Customer </Button>
                            <Table style={{ marginTop: 32, fontSize: 14 }} size="sm" bordered hover>
                                <thead>
                                    <tr>
                                        <th>Customer Name</th>
                                        <th>Notes</th>
                                        <th>Entitlement Secret</th>
                                        <th>Customer ID</th>
                                        <th>Version</th>
                                        <th style={{ width: 200 }}></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {customerEntitlements.map((user, index) => (
                                        <tr key={index}>
                                            <td>{user.customerName}</td>
                                            <td>{user.accessDoc}</td>
                                            <td>{user.entitlementSecret}</td>
                                            <td>{user.customerID}</td>
                                            <td>{user.version}</td>
                                            <td>
                                                { loadingStates[index] ? 
                                                    <Spinner/> 
                                                    :
                                                    <Button size="sm" onClick={() => handleValidation(user, index)}>Validate Credentials</Button>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Stack>
                        { showInviteModal && 
                            <InviteUserModal
                                onHide={() => setShowInviteModal(false)}
                            />
                        }
                        { showNewCustomerModal && 
                            <GenerateKeyModal
                                onHide={() =>  
                                    {
                                        setShowNewCustomerModal(false)
                                        getCustomerEntitlements();
                                    }
                                }
                            />
                        }
                    </>
                    :
                    <>
                        { isCustomer ? 
                            <>
                                <div style={{ display: "flex", justifyContent: "right" }}>
                                    <Button size="sm" style={{ width: 150 }} onClick={handleLogout}> Sign Out </Button>
                                </div>
                                { hasCompanies ? 
                                    <>
                                        <h6 style={{marginTop: 16}}>Use this tool to manage your USCIS web service accounts and credentials.</h6>
                                        <h6>Please note that USCIS web service passwords expire every 90 days and will need to be reset with the “Update Credentials” button below.</h6>
                                        <Table style={{ marginTop: 16, fontSize: 14, width: 600 }} size="sm" bordered hover>
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: 200 }}>Company</th>
                                                        <th style={{ width: 200 }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {companies.map((company, index) => (
                                                        <tr key={index}>
                                                            <td style={{verticalAlign: "middle"}}>{company.mouCompanyName}</td>
                                                            <td>
                                                                {<Stack direction="horizontal" gap={3} style={{ justifyContent: "center", alignItems: "center" }}>
                                                                    <Button size="sm" onClick={() => handleUpdateClick(company)}>Update Credentials</Button>
                                                                    <Button size="sm" onClick={() => handleRemoveClick(company)}>Remove</Button>
                                                                </Stack>}
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                        </Table>
                                        <Button size="sm" onClick={() => handleAddMoreClick()}>Add New</Button>
                                    </>
                                : 
                                <div></div> }
                                { showCredentialForm &&
                                    <AddCredentialModal 
                                        currentUser={currentUser}
                                        company={selectedCompany} 
                                        onHide={async (success: boolean) => {
                                            setShowCredentialForm(false);
                                            await fetchMOUCompanies(currentUser.customerId);
                                            if (success) {
                                                toast.success("Success!");
                                            }
                                        }} 
                                    />
                                    
                                }
                                { showConfirmation && 
                                    <ConfirmationModal
                                        companyName={selectedCompany?.mouCompanyName as string}
                                        onCancel={() =>  {
                                            setShowConfirmation(false);
                                        }}
                                        onConfirm={async () => {
                                            setShowConfirmation(false);
                                            setLoading(true);
                                            await authClient.removeMOUCompany(currentUser.customerId, selectedCompany?.mouCompanyID.toString() ?? "");
                                            setSelectedCompany(null);
                                            await fetchMOUCompanies(currentUser.customerId);
                                            setLoading(false);
                                        }}
                                    />
                                }
                            </>
                            :
                            <ErrorBoundary/>
                        }
                    </>
                    }
                </>
                }
            </AuthenticatedTemplate>
        </PageLayout>
    );
}