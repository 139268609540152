import { useLocation } from "react-router-dom";
import * as Icon from 'react-bootstrap-icons';
import AdminSettingsPage from "../pages/admin-settings/AdminSettingsPage";

export type PageMeta = PageMetaRoot | PageMetaTopLevel | PageMetaChild;

export interface PageMetaBase {
  fullPath: string,
  relativePath: string,
  element?: React.ReactNode,
  pageName: string,
  subPages?: PageMeta[],
  parent?: PageMeta,
  isInSiteNav: boolean,
}

export interface PageMetaRoot extends PageMetaBase {
  subPages: PageMetaTopLevel[],
  parent: undefined,
  isInSiteNav: false,
}

export interface PageMetaTopLevel extends PageMetaBase {
  subPages?: PageMetaChild[],
  parent: PageMetaRoot,
  icon: React.ReactNode,
  isInSiteNav: boolean,
}

export interface PageMetaChild extends PageMetaBase {
  subPages?: PageMetaChild[],
  parent?: PageMetaTopLevel | PageMetaChild,
  isInSiteNav: boolean,
}

interface PageMetaMap {
  pageMeta:PageMeta
  children: {
    [pathPart:string]: PageMetaMap,
  },
  paramMap?: PageMetaMap,
}

export function usePageMeta(): { url:string, meta:PageMeta }[] {
  const location = useLocation();
  const pathParts = location.pathname.replace(/\/+$/, '').split('/');
  let meta:{ url:string, meta:PageMeta }[] = [];
  let map = pagesMap;
  let path = '';
  pathParts.splice(0, 1);
  while (pathParts.length > 0) {
    const pathPart = pathParts.splice(0, 1)[0];
    path += '/' + pathPart;
    map = map.children[pathPart] ?? map.paramMap
    if (map === undefined) {
      return [];
    }
    meta.push({ url:path, meta:map.pageMeta });
  }
  return meta;
}

const pages: PageMetaRoot[] = [
  {
    fullPath: '',
    relativePath: '/',
    pageName: 'Home',
    isInSiteNav: false,
    parent: undefined,
    element: <AdminSettingsPage/>,
    subPages: [
      {
        fullPath: '',
        relativePath: 'settings',
        pageName: 'Settings',
        icon: <Icon.Person />,
        isInSiteNav: true,
        parent: undefined as unknown as PageMetaRoot,
        element: <AdminSettingsPage/>,
      },
    ],
  },
];
export const rootPage = pages[0];
setPageFullPathAndParent(rootPage);
const pagesMap:PageMetaMap = getPageMap(rootPage);

function setPageFullPathAndParent(page:PageMeta, parent?:PageMeta) {
  if (parent) {
    const parentPathWithoutTrailingSlash = parent.fullPath.replace(/\/+$/, '');
    page.fullPath = `${parentPathWithoutTrailingSlash}/${page.relativePath}`;
    page.parent = parent;
  } else {
    page.fullPath = page.relativePath;
  }  
  page.subPages?.forEach(x => setPageFullPathAndParent(x, page));
}

function getPageMap(page:PageMeta):PageMetaMap {
  const map:PageMetaMap = {
    pageMeta: page,
    children: {},
  };
  page.subPages?.forEach(x => {
    if (x.relativePath.startsWith(':')) {
      if (map.paramMap) {
        throw new Error("Path has multiple param matchers which isn't allowed: " + page.fullPath);
      }
      map.paramMap = getPageMap(x);
    } else {
      map.children[x.relativePath] = getPageMap(x);
    }
  });
  return map;
}