import React, { ErrorInfo, ReactNode } from "react";
import { ErrorDetails } from "./ErrorDetails";

interface Props {
    children?: ReactNode;
}
interface State {
    error?: Error;
}

export default class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            error: undefined,
        };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { 
            error,
        };
    }

    componentDidCatch(_error: Error, _errorInfo: ErrorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.error) {
            // You can render any custom fallback UI
            return (
                <div>
                    <ErrorDetails message="Something went wrong" error={this.state.error} />
                </div>
            );
        }

        return this.props.children;
    }
}
