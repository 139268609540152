import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
  names: {
      signUpSignIn: 'B2C_1_SignUpSignIn',
      signInOnly: 'B2C_1_SignInOnly',
      forgotPassword: 'B2C_1_PasswordReset',
      editProfile: 'B2C_1_EditProfile',
  },
  authorities: {
      signUpSignIn: {
        authority: 'https://fiddletreeb2c.b2clogin.com/fiddletreeb2c.onmicrosoft.com/B2C_1_SignUpSignIn',
      },
      signInOnly: {
        authority: 'https://fiddletreeb2c.b2clogin.com/fiddletreeb2c.onmicrosoft.com/B2C_1_SignInOnly'
      },
      forgotPassword: {
        authority: 'https://fiddletreeb2c.b2clogin.com/fiddletreeb2c.onmicrosoft.com/B2C_1_PasswordReset',
      },
      editProfile: {
        authority: 'https://fiddletreeb2c.b2clogin.com/fiddletreeb2c.onmicrosoft.com/B2C_1_EditProfile',
      },
      token: {
        authority: 'https://fiddletreeb2c.b2clogin.com/fiddletreeb2c.onmicrosoft.com/B2C_1_SignUpSignIn/oauth2/v2.0/token',
      },
  },
  authorityDomain: 'fiddletreeb2c.b2clogin.com',
};

export const msalConfig = {
  auth: {
    clientId: '60c50c43-4728-4ba6-91b5-1f91772db561',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: true,
    scopes: ['openid', 'profile', 'email']
  },
  cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
  scopes: ['openid', 'profile', 'email'],
};