import { FieldName } from "./interfaces/fields.interface"

export enum AssetType {
    SiteAssets   = 'SiteAssets',
    Asset       = 'Asset',
    SiteRequest = 'SiteRequest',
    SiteRole = 'SiteRole',
    RateTemplate    = 'RateTemplate',
    RateHistory     = 'RateHistory',
    SiteOrders = 'SiteOrders',
    ReceiveOrder    = 'ReceiveOrder',
    Invoice         = 'Invoice',
    Request        = 'Request',
    Warehouse = 'Warehouse',
    Position = 'Position',
    Phase = 'Phase',
    AssetCategory = 'AssetCategory',
    Certification = 'Certification',
    Jobsite = 'Jobsite',
    JobSitePhase = 'JobSitePhase',
    Crew = 'Crew',
    CrewMember = 'CrewMember',
    CrewMemberRequest = 'CrewMemberRequest',
    JobSiteCertification = 'JobSiteCertification',
    EmployeeCertification = 'EmployeeCertification',
    Employee = 'Employee',
    CrewType = 'CrewType',
    AssetCertification = 'AssetCertification',
    WorkTypeGantt   = 'WorkTypeGantt',
    Tag = 'Tag',
    JobsiteTag = 'JobsiteTag',
    BusinessGroup = 'BusinessGroup',
    RequestTemplate = 'RequestTemplate',
    RequestTemplateDetail = 'RequestTemplateDetail',
    PermissionGroup = 'PermissionGroup',
    PermissionGroupBusinessGroup = 'PermissionGroupBusinessGroup',
    PermissionGroupAssignment = 'PermissionGroupAssignment',
    PermissionGroupRule = 'PermissionGroupRule',
    RequestPeriod = 'RequestPeriod',
    User = 'User',
    Customer = 'Customer'
}

export enum RequestType {
    None = '',
    Employee = 'Employee',
    Asset = 'Asset',
  }

export enum PermissionGroupType {
    BusinessGroup = "Business Group",
    Position = "Position",
    CraftClass = "Craft/Class",
    JobSite = "JobSite",
    State = "State",
    EarnType = "Earn Type"
}

export enum PermissionGroupInclude {
    Included = "Is Included",
    Excluded = "Is Excluded"
}

export interface GridRowValues {
    AssetID: string,
    /** Property that holds all row values when loaded from a Gantt chart */
    taskData?: Record<string, any>,
    [key: string]: any,
}

export interface DataGridField {
    sourceView: string,
    fieldId: number,
    fieldName: FieldName,
    label: string,
    shortLabel: string,
    type: FieldValueType,
    editType: EditType,
    template: string,
    ordinal: number,
    isEditable: boolean,
    isRequired: boolean,
    showInGrid: boolean,
}

export interface FieldValue {
    value: number|string,
    displayValue: string,
    values: unknown[]
}

export interface Asset {
    AssetCategoryID: number,
    CurrentWarehouseID: number,
    CurrentSiteID: number,
    AssetNumber: string,
    AssetDescription: string,
    Manufacturer: string,
    Model: string,
    SerialNumber: string,
    Barcode: string,
    Status: number,
    PurchaseDate: string,
    PurchasePrice: number,
    WarrantyExpirationDate: string,
    AssetNotes: string,
    ResponsiblePerson: string
}

export interface RequestOrder {
    AssetID: number,
    AssetCategoryID: number,
    UM: string,
    Units: number,
    JobsiteID: number,
    WarehouseID: number,
    StartDate: string,
    ProjectedEndDate: string,
    EndDate: string,
    RatePeriod: string,
    DateReceived: string,
    ReceivedByUserID: number,
    OrderStatus: string,
    ResponsibleUserID: number,
    PackageID: number
}

export interface Warehouse {
    WarehouseName: string,
    Address: string,
    Address2: string,
    City: string,
    State: string,
    Zip: string
}

export interface Position {
    PositionTitle: string,
}

export interface Phase {
    PhaseDescription: string,
    Notes: string
}

export interface BusinessGroup {
    BusinessGroupDescription: string,
    Color: string
}

export interface WorkCategory {
    WorkCategoryDescription: string,
    ShortLabel: string,
    Color: string
}

export interface AssetCategory {
    AssetCategoryName: string,
    AssetCategoryType: number,
    UM: string,
    Notes: string
}

export interface CrewType {
    CrewTypeDescription: string,
    Notes: string
}

export interface Certification {
    CertificationDescription: string,
    Color: any,
    ShortLabel: string
}

export interface TenantUser {
    CustomerID : string,
    UserFirstName : string,
    UserLastName : string,
    UserMiddleName : string,
    PreferredName: string,
    Pronoun: string,
    UserEmail: string,
    WorkEmail: string,
    Address: string,
    Address2: string,
    City: string,
    State: string,
    Zip: string,
    WorkPhone: string,
    PersonalPhone: string,
    Craft: string,
    Class: string,
    PositionID: string
}

export interface Crew {
    CrewTypeID : number,
    CrewLeaderID: string,
    TenantData: string,
    CrewDescription: string,
    Notes: string
}

export interface RequestAsset {
    RequestType : string,
    TenantRequestID: number,
    JobsiteID: number,
    BeginDate: string,
    ProjectedEndDate: string,
    EndDate: string,
    AssetID: number,
    AssetCategoryID: string,
    PositionID: string,
    CrewTypeID: string,
    UM: string,
    Qty: number,
    RatePeriod: string,
    DateReceived: string,
    ReceivedByUserID: string,
    RequestStatus: string,
    ResponsibleUserID: string,
    PackageID: number,
    TenantData: string,
    AllocationPercentage: number,
    CrewID: number,
    UserID: string,
    StartTime: string,
    JobSitePhaseID: number,
    RequestedResource: string,
}

export interface JobSite {
    CustomerID : number,
    JobName: string,
    Address: string,
    Address2: string,
    City: string,
    State: string,
    Zip: string,
    Notes: string,
    BeginDate: string,
    ProjectedEndDate: string,
    CurrentRateTemplateRevID: number,
    TenantData: string,
    Status: string,
    OpportunityStatus: string,
    JobID: number,
    BusinessGroupID: number,
    Color: string
}

export enum FieldValueType {
    Money = 'Money',
    DateTime = 'DateTime',
    String = 'String',
    Integer = 'Integer',
    Button = 'Button',
    Numeric = 'Numeric',
    GUID = 'GUID',
    Color = 'Color',
    Bit = 'Bit'
}

export enum EditType {
    TypeAhead = 'TypeAhead',
    Dropdown = 'Dropdown',
    Integer = 'Integer',
    Numeric = 'Numeric',
    DateTime = 'DateTime',
    MultiLineText = 'MultiLineText',
    SingleLineText = 'SingleLineText',
    RGB = 'RGB',
    Checkbox = 'Checkbox'
}

/** SyncFusion DataGrid column type */
export enum ColumnType {
    /** A string type column. */
    String = "String",
    /** A number type column. Primitive types such as int, int?, float, double, decimal etc. are consider as number type column. */
    Number = "Number",
    /** A boolean type column. */
    Boolean = "Boolean",
    /** A date type column. Primitive types such as date time and date time offset are consider as date type column. */
    Date = "Date",
    /** A date time type column. Primitive types such as date time and date time offset are consider as date type column. */
    DateTime = "DateTime",
    /** Enables checkbox column for selection purpose. No data operation is associated with this column. */
    Checkbox = "CheckBox",
}

export type TypeAheadOption = string | Record<string, any>;

export enum RelativeDateUnit {
    days = "days",
    weeks = "weeks",
}

export enum NumberFilterOperatorType {
    equals = "eq",
    notEquals = "neq",
    greaterThan = "gt",
    lessThan = "lt",
    greaterThanEqual = "gte",
    lessThanEqual = "lte",
    between = "between",
}

export enum StringArrayFilterOperatorType {
    isOneOf = "isOneOf",
}

export enum StringFilterOperatorType {
    equals = "eq",
    notEquals = "neq",
    startsWith = "startsWith",
    endsWith = "endsWith",
    contains = "contains",
}

export enum DateFilterOperatorType {
    inTheLast = "inTheLast",
    equals = "eq",
    notEquals = "neq",
    beforeDate = "beforeDate",
    afterDate = "afterDate",
    betweenDates = "betweenDates",
}

export type FilterOperatorType = NumberFilterOperatorType | StringFilterOperatorType | StringArrayFilterOperatorType | DateFilterOperatorType;

export interface BaseFilter {
    fieldId: number,
    fieldName: string,
    fieldType: FieldValueType,
}

export interface NumberFilter extends BaseFilter {
    operator: Exclude<NumberFilterOperatorType, NumberFilterOperatorType.between>
    operand: number,
}

export interface BetweenNumberFilter extends BaseFilter {
    operator: NumberFilterOperatorType.between
    range: { start: number, end: number }
}

export interface StringArrayFilter extends BaseFilter {
    operator: StringArrayFilterOperatorType
    operand: string[]
}

export interface StringFilter extends BaseFilter {
    operator: StringFilterOperatorType
    operand: string
}

export interface RelativeDateFilter extends BaseFilter {
    operator: DateFilterOperatorType.inTheLast
    amount: number
    unit: RelativeDateUnit 
}

export interface ExactDateFilter extends BaseFilter {
    operator: DateFilterOperatorType.equals | DateFilterOperatorType.notEquals | DateFilterOperatorType.beforeDate | DateFilterOperatorType.afterDate
    date: string // ISO formatted string
}

export interface ExactBetweenDateFilter extends BaseFilter {
    operator: DateFilterOperatorType.betweenDates
    range: { start: string, end: string }
}

export type Filter = NumberFilter | BetweenNumberFilter | StringFilter | StringArrayFilter
    | RelativeDateFilter | ExactDateFilter | ExactBetweenDateFilter;
