import { Theme, useTheme } from "./themes/ThemeProvider";
import { useEffect } from "react";

import { Navbar, Image } from "react-bootstrap";

function Navigation() {
    const [, setTheme] = useTheme();
    
    useEffect(() => {
        setTheme(Theme.light)
    })

    return (
        <Navbar expand="md">
            <Image src="/logo192.png" width={48} height={48} style={{ marginRight: "8px" }} />
            <Navbar.Brand href="/">Fiddletree Systems</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
        </Navbar>
    );
}

export default Navigation;
