import { ChangeEvent, useEffect, useState } from "react";
import { AscendError } from "./AscendError";
import { Service, OperationalError } from "./OperationalError";
import * as base64 from 'base-64';
import * as utf8 from 'utf8';

export const isDevMode = (): boolean => (global as Record<string, any>).__REACT_DEVTOOLS_GLOBAL_HOOK__ !== undefined;
export enum LoadingState {
    Loading = "Loading",
    Loaded = "Loaded",
    ErrorLoading = "ErrorLoading",
}
export type RemoteResource<T>  = LoadingResource | LoadedResource<T> | ErrorLoadingResource;
export type ErrorLoadingResource = { loadingState: LoadingState.ErrorLoading, data: undefined, error: OperationalError };
export type LoadingResource = { loadingState: LoadingState.Loading, data: undefined };
export type LoadedResource<T>  = { loadingState: LoadingState.Loaded, data: T };

export function useRequest<ResponseDataType, FormattedResponseDataType>(options:RequestOptions<ResponseDataType, FormattedResponseDataType>
): RemoteResource<FormattedResponseDataType> {
    const [response, setResponse] = useState<RemoteResource<FormattedResponseDataType>>({ loadingState: LoadingState.Loading, data: undefined });
    const { url, resource, onResponseReceived, isWaitingForResource } = options;
    const urlStr = url.toString();
    useEffect(() => {
        if (isWaitingForResource === true) return;
        const abortController = new AbortController();
        async function fetchData() {
            const fetchResponse = await fetchJson<ResponseDataType>(urlStr, "useRequestData", resource, abortController.signal);
            const responseData = onResponseReceived
                ? onResponseReceived(fetchResponse)
                : fetchResponse as FormattedResponseDataType;
            setResponse({
                loadingState: LoadingState.Loaded,
                data: responseData,
            });
        };
        fetchData().catch((ex: OperationalError) => {
            // An abort error occurs when a request is cancelled which may happen when navigating away from a page or
            // content where it was needed. This error can be ignored as the response is no longer needed, hence the abort.
            if (AscendError.isAbortError(ex)) return;
            setResponse({ loadingState: LoadingState.ErrorLoading, error: ex, data: undefined });
        });
        return () => {
            abortController.abort();
        }
    }, [setResponse, urlStr, resource, onResponseReceived, isWaitingForResource]);
    return response;
}

/**
 * Fetches data from an endpoint, parses the data as json, logs any request or parsing errors, and returns data as an indicated generic type.
 * @param url The url from which to fetch data
 * @param logGroupPrefix Prefixes all log groups logged with this prefix. Usually this is the name of the calling function like "useSiteAssets".
 * @param resource A name of the resource being requested. This is used in logs. e.g. "site assets".
 * @param context An object containing useful context used when logging.
 * @returns the parsed response data as the indicated generic type.
 */
export async function fetchJson<T>(url:string|URL, logGroupPrefix:string, resource:string, signal?: AbortSignal, context?:Record<string, any>): Promise<T> {
    let fetchResponse:Response;
    const getErrContext = () => ({ ...context, url });
    try {
        fetchResponse = await fetch(url, {
            headers: {
                'X-Service-Trace': Service.AscendUi,
            },
            signal,
        });
        if (fetchResponse.status >= 300) {
            let errorResponse:unknown = undefined;
            try {
                errorResponse = await fetchResponse.json();
            } finally {
                throw new OperationalError("FetchJson:BadResponseCode", `Bad response code ${fetchResponse.status}`, Service.AscendApi, null, {
                    statusCode: fetchResponse.status,
                    response: errorResponse,
                });
            }
        }
    } catch (ex) {
        throw new OperationalError(`${logGroupPrefix}:FetchJson:RequestError`, `Error fetching ${resource}`, Service.AscendApi, ex as Error, getErrContext());
    }

    try {
        const jsonResponse = await fetchResponse.json() as Promise<T>;
        return jsonResponse;
    } catch (ex) {
        throw new OperationalError(`${logGroupPrefix}:FetchJson:ParseResponse`, `Error parsing response for ${resource}`, Service.AscendApi, ex as Error, getErrContext());
    }
}

export async function putJson(url:string|URL, body:unknown, logGroupPrefix:string, resource:string, signal?: AbortSignal, context?:Record<string, any>): Promise<void> {
    let fetchResponse:Response;
    const getErrContext = () => ({ ...context, url });
    try {
        fetchResponse = await fetch(url, {
            method: 'PUT',
            headers: {
                'X-Service-Trace': Service.AscendUi,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            signal,
        });
        if (fetchResponse.status >= 300) {
            let errorResponse:unknown = undefined;
            try {
                errorResponse = await fetchResponse.json();
            } finally {
                throw new OperationalError("BadResponseCode", `Bad response code ${fetchResponse.status}`, Service.AscendApi, null, {
                    statusCode: fetchResponse.status,
                    response: errorResponse,
                });
            }
        }
    } catch (ex) {
        throw new OperationalError(`${logGroupPrefix}:PutJson:RequestError`, `Error updating ${resource}`, Service.AscendApi, ex as Error, getErrContext());
    }
}

export async function deleteResource(url:string|URL, logGroupPrefix:string, resource:string, context?:Record<string, any>): Promise<void> {
    const getErrContext = () => ({ ...context, url });
    try {
        const fetchResponse = await fetch(url, {
            method: 'DELETE',
            headers: {
                'X-Service-Trace': Service.AscendUi,
            },
        });
        if (fetchResponse.status >= 300) {
            let errorResponse:unknown = undefined;
            try {
                errorResponse = await fetchResponse.json();
            } finally {
                throw new OperationalError("BadResponseCode", `Bad response code ${fetchResponse.status}`, Service.AscendApi, null, {
                    statusCode: fetchResponse.status,
                    response: errorResponse,
                });
            }
        }
    } catch (ex) {
        throw new OperationalError(`${logGroupPrefix}:DeleteResource:RequestError`, `Error deleting ${resource}`, Service.AscendApi, ex as Error, getErrContext());
    }
}

/**
 * Posts data to an endpoint, logs any request or parsing errors, and returns data as an indicated generic type.
 * @param url The url from which to fetch data
 * @param logGroupPrefix Prefixes all log groups logged with this prefix. Usually this is the name of the calling function like "useSiteAssets".
 * @param resource A name of the resource being requested. This is used in logs. e.g. "site assets".
 * @param context An object containing useful context used when logging.
 * @param data JSON of the data to be posted
 * @returns the assetId if post was successful
 */
export async function postJson<T>(url:string|URL, body:unknown, logGroupPrefix:string, resource:string, signal?: AbortSignal, context?:Record<string, any>): Promise<T> {
    let fetchResponse:Response;
    const getErrContext = () => ({ ...context, url });
    try {
        fetchResponse = await fetch(url, {
            method: 'POST',
            headers: {
                'X-Service-Trace': Service.AscendUi,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            signal,
        });
        if (fetchResponse.status >= 300) {
            let errorResponse:unknown = undefined;
            try {
                errorResponse = await fetchResponse.json();
            } finally {
                throw new OperationalError("BadResponseCode", `Bad response code ${fetchResponse.status}`, Service.AscendApi, null, {
                    statusCode: fetchResponse.status,
                    response: errorResponse,
                });
            }
        }
    } catch (ex) {
        throw new OperationalError(`${logGroupPrefix}:PostJson:RequestError`, `Error updating ${resource}`, Service.AscendApi, ex as Error, getErrContext());
    }

    try {
        const jsonResponse = await fetchResponse.json() as Promise<T>;
        return jsonResponse;
    } catch (ex) {
        throw new OperationalError(`${logGroupPrefix}:PostJson:ParseResponse`, `Error parsing response for ${resource}`, Service.AscendApi, ex as Error, getErrContext());
    }
}

export function getUrl(url: string|URL, params?: Record<string, string|number>): URL {
    const responseUrl = new URL(url, window.location.href);
    if (params) {
        Object.keys(params).forEach(x => {
            const paramValue = params[x];
            if (typeof paramValue === 'string') {
                responseUrl.searchParams.append(x, paramValue);
            } else if (typeof paramValue === 'number') {
                responseUrl.searchParams.append(x, '' + paramValue);
            } else {
                guardAgainstUnexpectedValue(paramValue, { url, params });
            }
        })
    }
    return responseUrl;
}

export function guardAgainstUnexpectedValue(val:never, context?:Record<string,any>): never {
    const errorMsg = "Reached code that should be unreachable. This typically happens in switch case statements where a default condition is unexpectedly reached.";
    throw new AscendError("guardAgainstUnexpectedValue", errorMsg, null, { ...context, unexpectedValue:val });
}

const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = 60 * MILLIS_PER_SECOND;
const MILLIS_PER_HOUR = 60 * MILLIS_PER_MINUTE;

export function timeInMillis({ hours = 0, minutes = 0, seconds = 0, millis = 0 }: { hours?: number, minutes?: number, seconds?: number, millis?: number }) {
    return hours * MILLIS_PER_HOUR
        + minutes * MILLIS_PER_MINUTE
        + seconds * MILLIS_PER_SECOND
        + millis;
}

type ForbiddenHeaderNames = "Accept-Charset" | "Accept-Encoding" | "Access-Control-Request-Headers" | "Access-Control-Request-Method"
    | "Connection" | "Content-Length" | "Cookie" | "Date" | "DNT" | "Expect" | "Host" | "Keep-Alive" | "Origin" | "Permissions-Policy"
    | "Proxy-" | "Sec-" | "Referer" | "TE" | "Trailer" | "Transfer-Encoding" | "Upgrade" | "Via";
interface RequestOptions<ResponseDataType, FormattedResponseDataType> {
    url: string|URL,
    body?: unknown,
    headers?: Record<Exclude<string, ForbiddenHeaderNames>, string>,
    isWaitingForResource?: boolean,
    onResponseReceived?: (response: ResponseDataType) => FormattedResponseDataType,
    resource: string,
}

export function moveArrayItem<T>(array:T[], fromIndex:number, toIndex:number) {
    let element = array[fromIndex];
    array.splice(fromIndex, 1);
    array.splice(toIndex, 0, element);
    return array;
}

/**
 * Converts an iso date string into a string of the format 'yyyy-mm-dd' like the browser expects
 */
export function getDateInputStr(isoDateStr?: string): string {
    if (!isoDateStr) return "";
    const date = new Date(isoDateStr);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getDateFromChangeEvent(event: ChangeEvent<HTMLInputElement>): Date|undefined {
    const value = event.target.value;
    if (!value) return undefined;
    const [ year, month, day ] = value.split('-').map((x: string) => parseInt(x));
    const monthIndex = month - 1;
    const date = new Date(year, monthIndex, day);
    return date;
}

const numberFormatterNoDecimal = new Intl.NumberFormat(undefined, {
    currency: 'USD',
    maximumFractionDigits: 0,
});

const numberFormatterSingleDecimal = new Intl.NumberFormat(undefined, {
    currency: 'USD',
    maximumFractionDigits: 1,
});

const currencyFormatterNoDecimal = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 0,
});

const currencyFormatterSingleDecimal = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 1,
});

export function getFormattedNumber(num: number): string {
    return getFormattedNumber_helper(num, numberFormatterNoDecimal, numberFormatterSingleDecimal);
}

export function getFormattedCurrency(num: number): string {
    return getFormattedNumber_helper(num, currencyFormatterNoDecimal, currencyFormatterSingleDecimal);
}

function getFormattedNumber_helper(num: number, formatterNoDecimals: Intl.NumberFormat, formatterSingleDecimal: Intl.NumberFormat): string {
    if (num > 1_000_000) {
        const millions = num / 1_000_000;
        const suffix = " M";
        return num >= 10_000_000
            ? formatterNoDecimals.format(millions) + suffix
            : formatterSingleDecimal.format(millions) + suffix;
    }
    if (num > 1_000) {
        const thousands = num / 1_000;
        const suffix = " K";
        return num > 10_000
            ? formatterNoDecimals.format(thousands) + suffix
            : formatterSingleDecimal.format(thousands) + suffix;
    }
    return formatterNoDecimals.format(num);
}

export const encodeAsBase64JsonStr = (obj: object|unknown[]): string => {
    const jsonStr = JSON.stringify(obj);
    const bytesStr = utf8.encode(jsonStr);
    return base64.encode(bytesStr);
}

export const decodeAsBase64JsonStr = <T>(base64EncodedStr: string):T => {
    const bytesStr = base64.decode(base64EncodedStr);
    const jsonStr = utf8.decode(bytesStr);
    const jsonObj = JSON.parse(jsonStr);
    return jsonObj as T;
}
