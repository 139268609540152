export type Context = Record<string, any>;
export type ErrorContext = Record<string, any> & { errorType:ErrorType };
export enum ErrorType {
    Client = 'Client',
    Self = 'Self',
    Dependency = 'Dependency',
}

export class AscendError extends Error {
    public context:ErrorContext;

    constructor(public logGroup:string, message:string, cause?:Error|null, context?:Context) {
        super(message, { cause: cause ?? undefined });
        this.message = cause ? `${message}: ${cause.message}` : message;
        if (cause) {
            this.stack = this.stack + '\ncaused by: ' + AscendError.getStack(cause);
        }
        this.context = Object.assign({ errorType: ErrorType.Self }, context);
    }

    public static getStack(error:Error): string|undefined {
        if (error instanceof AscendError) {
            return error.stack;
        }
        if (error.cause) {
            return error.stack + '\ncaused by: ' + this.getStack(error.cause as Error);
        }
        return error.stack;
    }

    public static getContext(error:Error): Context {
        let context = {};
        this.visitErrorsReverse(error, (currentError) => {
            if (currentError instanceof AscendError) {
                Object.assign(context, currentError.context)
            }
        });
        return context;
    }

    public static getLogGroups(error:Error): string[] {
        const logGroups:string[] = [];
        this.visitErrors(error, (currentError) => {
            if (currentError instanceof AscendError) {
                logGroups.push(currentError.logGroup);
            }
        });
        return logGroups;
    }

    public static isAbortError(error:Error): boolean {
        let isAbortError = false;
        this.visitErrors(error, (childError) => {
            isAbortError = isAbortError
                || (childError as { code?: number }).code === DOMException.ABORT_ERR
                || (childError as { isAbortedRequest?: boolean }).isAbortedRequest === true;
        });
        return isAbortError;
    }

    public static visitErrors(error:Error, callback:(error:Error) => void) {
        let currentErr:Error|undefined = error;
        while (currentErr) {
            callback(currentErr);
            currentErr = currentErr.cause as Error|undefined;
        }
    }

    public static visitErrorsReverse(error:Error, callback:(error:Error) => void) {
        const errors:Error[] = [];
        this.visitErrors(error, currentError => {
            errors.splice(0, 0, currentError);
        });
        while (errors.length > 0) {
            const error = errors.shift();
            callback(error as Error);
        }
    }
}
AscendError.prototype.name = AscendError.name;
