import { useMemo } from "react";
import { AscendError, ErrorType } from "../logic/AscendError";

export function useLogger(): Logger {
    const logger = useMemo(() => new LoggerImpl(), []);
    return logger;
}

export function getLogContext(logGroup:string, message:string, error?:Error|null, context?:Context): LogContext {
    const logContext = { logGroup, message, errorType: ErrorType.Self, logGroups: [logGroup] };
    if (!error) return Object.assign(logContext, context);
    logContext.logGroups = [logGroup, ...AscendError.getLogGroups(error)];
    logContext.logGroup = logContext.logGroups.at(-1) as string;
    logContext.message = `${message}: ${error.message}`;
    const errorContext = AscendError.getContext(error);
    return Object.assign(logContext, errorContext, context);
}

export interface LogContext {
    logGroup: string,
    logGroups: string[],
    message: string,
    errorType: ErrorType,
    statusCode?: number,
    response?: any,
    [key: string]: unknown,
}

type Context = Record<string, any>;

interface Logger {
    info: (logGroup:string, message:string, context?: Context) => void,
    warn: (logGroup:string, message:string, error?: Error|null, context?: Context) => void,
    error: (logGroup:string, message:string, error?: Error|null, context?: Context) => void,
}

class LoggerImpl implements Logger {
    public info(logGroup:string, message:string, context?:Context) {
        console.info(`Info (${logGroup}) ${message}`);
        if (context) {
            console.info(getLogContext(logGroup, message, undefined, context));
        }
    }

    public warn(logGroup:string, message:string, error?:Error|null, context?:Context) {
        const logContext = getLogContext(logGroup, message, error, context);
        const { logGroup: _logGroup, message: _message, ...remainingContext } = logContext;
        console.warn(`Warn (${logContext.logGroup}) ${logContext.message}\n${JSON.stringify(remainingContext, null, 3)}`);
    }

    public error(logGroup:string, message:string, error?:Error|null, context?:Context) {
        const logContext = getLogContext(logGroup, message, error, context);
        const { logGroup: _logGroup, message: _message, ...remainingContext } = logContext;
        console.error(`Error (${logContext.logGroup}) ${logContext.message}\n${JSON.stringify(remainingContext, null, 3)}`);
    }
}
