interface FixedFieldValuesProvider {
    getValuesForField(fieldName: string): FieldValueDisplayData<string|number>[]|undefined
}

export interface FieldValueDisplayData<T> {
    label: string
    value: T
}

class FixedFieldValuesProviderImpl implements FixedFieldValuesProvider {
    getValuesForField(fieldName: string): FieldValueDisplayData<string|number>[]|undefined {
        return fieldNameToHardCodedValueMap[fieldName];
    }
}

export const fixedFieldValuesProvider = new FixedFieldValuesProviderImpl();

const fieldNameToHardCodedValueMap: Record<string, FieldValueDisplayData<string|number>[]> = {
    "RequestType": [
        { label: "Employee", value: "Employee" },
        { label: "Asset", value: "Asset" },
    ],
    "RequestStatus": [
        { label: "Unassigned", value: "Unassigned" },
        { label: "Assigned", value: "Assigned" },
    ],
    "AssetStatus": [
        { label: "Unassigned", value: "Unassigned" },
        { label: "Assigned", value: "Assigned" },
    ],
    "AssetCategoryType": [
        { label: "Equipment", value: 1 },
        { label: "Small Tools", value: 2 },
        { label: "Consumables", value: 3 },
    ],
    "OpportunityStatus": [
        { label: "Active", value: "Active" },
        { label: "Closed - Lost", value: "Closed - Lost" },
        { label: "Closed - Won", value: "Closed - Won" },
        { label: "N/A", value: "N/A" },
    ],
    "JobStatus": [
        { label: "Active", value: "Active" },
        { label: "Closed", value: "Closed" },
        { label: "", value: "" },
    ],
    "Status": [
        { label: "Unassigned", value: 1 },
        { label: "Assigned", value: 2 },
        { label: "Inactive", value: 3 },
        { label: "Rented", value: 4 },
    ],
    "PermissionGroupType" : [
        { label: "Business Group", value: "BusinessGroup"},
        { label: "Position", value: "Position"},
        { label: "JobSite", value: "JobSite"},
        { label: "State", value: "State"},
        { label: "Earn Type", value: "EarnType"},
    ],
    "PermissionGroupInclude" : [
        { label: "Is Included", value: "Included"},
        { label: "Is Excluded", value: "Excluded"}
    ]
};
