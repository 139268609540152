import { AccountInfo } from "@azure/msal-browser";
import { deleteResource, fetchJson, postJson } from "../logic/tools";
import { AssetContext } from "../hooks/useAssetGridData";

export interface AuthClient {
}


interface ApiResponse<T> {
    data: T;
}

class AuthClientImpl {
    async checkUser<T>(account: AccountInfo): Promise<ApiResponse<T>> {
        const url = `/api/check-user/${encodeURIComponent(account.idToken as string)}`;
        const responsePromise = fetchJson<ApiResponse<T>>(url, "useCheckUser", 'user');
        const response = await responsePromise;
        return response
    }

    async createUser(newAsset: any, assetContext: AssetContext): Promise<void> {
        const query = this.getAssetContextQueryParams(assetContext);
        await postJson(`/api/create-user?${query}`, newAsset, `create ${assetContext.assetType}`, assetContext.assetType);
    }

    async resendInvite<T>(email: any): Promise<ApiResponse<T>> {
        const responsePromise = await postJson<ApiResponse<T>>(`/api/invite`, email, `invite user`, '');
        return responsePromise;
    }

    async createCustomerEntitlement<T>(newAsset: any): Promise<ApiResponse<T>> {
        const responsePromise = await postJson<ApiResponse<T>>(`/api/add-customer`, newAsset, `create customer entitlement`, 'entitlement');
        return responsePromise;
    }

    async updateCustomer<T>(newAsset: any, customerId: string, companyId: string): Promise<ApiResponse<T>> {
        const responsePromise = await postJson<ApiResponse<T>>(`/api/update-customer/${encodeURIComponent(customerId)}/${encodeURIComponent(companyId)}`, newAsset, `update customer`, 'customer');
        return responsePromise;
    }

    async validateCustomer<T>(newAsset: any, customerId: string): Promise<ApiResponse<T>> {
        const responsePromise = await postJson<ApiResponse<T>>(`/api/validate-customer/${encodeURIComponent(customerId)}`, newAsset, `validate customer`, 'validate');
        return responsePromise;
    }

    async getInvitedUsers<T>(): Promise<ApiResponse<T>> {
        const responsePromise = await fetchJson<ApiResponse<T>>(`/api/invited-users`, "", `get invited users`);
        return responsePromise;
    }

    async getCustomerEntitlements<T>(): Promise<ApiResponse<T>> {
        const responsePromise = await fetchJson<ApiResponse<T>>(`/api/customer-entitlements`, "", `get customer entitlements`);
        return responsePromise;
    } 

    async getCustomer<T>(email: string): Promise<ApiResponse<T>> {
        const responsePromise = await fetchJson<ApiResponse<T>>(`/api/get-customer/${encodeURIComponent(email)}`, email, `get customer`);
        return responsePromise;
    }

    async getMOUCompanies<T>(companyId: string): Promise<ApiResponse<T>> {
        const responsePromise = await fetchJson<ApiResponse<T>>(`/api/get-moucompanies/${encodeURIComponent(companyId)}`, companyId, `get mou companies`);
        return responsePromise;
    }

    async removeMOUCompany(customerId: string, companyId: string): Promise<void> {
        await deleteResource(`/api/remove-company/${encodeURIComponent(customerId)}/${encodeURIComponent(companyId)}`, companyId, `remove company`);
    }

    private getAssetContextQueryParams(assetContext: AssetContext): URLSearchParams {
        const query = new URLSearchParams({ 'assetType': assetContext.assetType });
        if ('contextId' in assetContext) {
            query.append('assetContextId', assetContext.contextId.toString());
        }
        return query;
    }
}

export const authClient = new AuthClientImpl();