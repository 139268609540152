import React from "react";
import { usePageMeta } from "../hooks/usePageMeta";
import { LoadingState, RemoteResource } from "../logic/tools";
import { Spinner } from "react-bootstrap";
import { AscendError } from "../logic/AscendError";

interface PageLayoutParams {
    pageName?: string,
    children?: React.ReactNode,
    error?: AscendError,
    remoteResources?: RemoteResource<unknown>[],
}

export default function PageLayout({ pageName, children, remoteResources, error }: PageLayoutParams) {
    const pages = usePageMeta();
    pageName ??= pages.at(-1)?.meta.pageName || "Unknown Page";
    const remoteResourceWithError: RemoteResource<unknown>|undefined = remoteResources?.find(x => x.loadingState === LoadingState.ErrorLoading);
    const isLoading = remoteResources?.find(x => x.loadingState === LoadingState.Loading) !== undefined;
    return (
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{width: '100%'}}>
                <h3 style={{display: "flex", justifyContent: "center"}}>{ pageName }</h3>
                <hr/>

                { remoteResourceWithError || error
                    ? (
                        remoteResourceWithError !== undefined
                            ? <div>An error occurred loading data. Please try refreshing the page.</div>
                            : <div>{error?.message ?? "An error occurred"}</div>
                    )
                    : (
                        isLoading
                            ? <Spinner />
                            : children ?? ""
                    )
                }
            </div>
        </div>
    );
}
