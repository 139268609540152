import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

interface ConfirmParams {
    companyName: string,
    onCancel: () => void,
    onConfirm: () => void
}

export function ConfirmationModal({ companyName, onCancel, onConfirm }: ConfirmParams) {
  return (
    <Modal style={{minWidth: 300}} show={true} onHide={onCancel}>
        <Modal.Header closeButton>
          <Modal.Title>Remove {companyName}?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="primary" onClick={onConfirm}>Confirm</Button>
        </Modal.Footer>
    </Modal>
  );
}