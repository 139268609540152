import { ChangeEvent, useState } from "react";
import { Button, Form, InputGroup, Modal, Spinner, Stack } from "react-bootstrap"
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { authClient } from "../../api-clients/AuthClient";

interface GenerateKeyParams {
    onHide: () => void
}

interface EntitlementSecret {
    customerID: string,
    entitlementSecret: string
}

export function GenerateKeyModal({ onHide }: GenerateKeyParams) {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [customerName, setCustomerName] = useState("");
    const [contractStart, setContractStart] = useState<Date | null>(null);
    const [contractEnd, setContractEnd] = useState<Date | null>(null);
    const [annualPrice, setAnnualPrice] = useState("0");
    const [completeText, setCompleteText] = useState("");

    const onCustomerNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setCustomerName(value);
    };

    const handleStartDateChange = (date: Date) => {
        setContractStart(date);
    };

    const handleEndDateChange = (date: Date) => {
        setContractEnd(date);
    };

    const onAnnualPriceChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setAnnualPrice(value);
    };

    async function onSend() {
        const newCustomerEntitlement = {
            CustomerName: customerName,
            ProductID: 1,
            ContractStartDate: contractStart,
            ContractEndDate: contractEnd,
            AnnualPrice: annualPrice

        }
        setLoading(true);
        try {
            const generatedKey = await authClient.createCustomerEntitlement<EntitlementSecret>(newCustomerEntitlement);
            if (generatedKey.data !== null || generatedKey.data !== undefined) {
                setIsSuccess(true);
                setCompleteText(`Key: ${generatedKey.data.entitlementSecret}`);
            }
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <Modal style={{minWidth: 300}} show={true} onHide={onHide}>
            <ToastContainer position="top-center"/>
            <Modal.Header closeButton>
                <Modal.Title>Invite New User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Stack direction="vertical" gap={3}>
                    <Stack direction="vertical">
                        <Form.Label>Customer Name</Form.Label>
                        <InputGroup>
                            <Form.Control type="text" size="sm" value={customerName ?? ""} onChange={onCustomerNameChange} required={true} />
                        </InputGroup>
                    </Stack>
                    <Stack direction="vertical">
                        <Form.Label>Contract Start</Form.Label>
                        <DatePicker onChange={(e) => { handleStartDateChange(e as unknown as Date)}} selected={contractStart}/>
                    </Stack>
                    <Stack direction="vertical">
                        <Form.Label>Contract End</Form.Label>
                        <DatePicker onChange={(e) => { handleEndDateChange(e as unknown as Date)}} selected={contractEnd}/>
                    </Stack>
                    <Stack direction="vertical">
                        <Form.Label>Annual Price</Form.Label>
                        <InputGroup>
                            <InputGroup.Text>$</InputGroup.Text>
                            <Form.Control type="number" size="lg" value={annualPrice} onChange={onAnnualPriceChange} />
                        </InputGroup>
                    </Stack>
                </Stack>
                { isSuccess ? 
                    <div style={{ display: "flex", justifyContent: "center", marginTop: 16 }}>
                        {completeText}
                    </div>
                :
                    <></>
                }
            </Modal.Body>
            <Modal.Footer>
                { isSuccess ? 
                    <Button variant="primary" onClick={onHide}>Close</Button>
                    :
                    <>
                        { isLoading ? 
                            <Spinner/> 
                            :
                            <Button variant="primary" onClick={onSend}>OK</Button>
                        }
                    </>
                }
            </Modal.Footer>
        </Modal>
    )
}