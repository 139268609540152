import { AscendError, Context } from "./AscendError";

export enum Service {
    AscendUi = 'ascend-inventory-ui',
    AscendApi = 'ascend-inventory-api',
}

export class OperationalError extends AscendError {
    constructor(public logGroup:string, message:string, errorSource:Service, cause?:Error|null, context?:Context) {
        super(logGroup, message, cause, context);
        this.context.errorSource = errorSource;
    }
}
