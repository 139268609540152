import { DataGridField } from "../types"

interface EnhancedFieldBase extends DataGridField {
    fieldType: FieldType
    displayGroup: FieldGroup
    filteredByFieldName?: FieldName
    lockFieldName?: FieldName
}

export interface VariantField extends EnhancedFieldBase {
    fieldType: FieldType.Variant
    variantControlFieldName: FieldName
    variantFieldNameByValue: Record<string|number, FieldName>
}

export interface DisplayField extends EnhancedFieldBase {
    fieldType: FieldType.Display
    valueFieldName: FieldName
}

export interface ValueField extends EnhancedFieldBase {
    fieldType: FieldType.Value
    displayFieldName: FieldName
}

export interface DisplayAndValueField extends EnhancedFieldBase {
    fieldType: FieldType.DisplayAndValue
}

export enum FieldGroup {
    None = 'None',
    Information = 'Information',
    RateRange = 'Rate Range',
    AddressInfo = 'Address Info',
    ContactInfo = 'Contact Info',
    Job = 'Job',
    RequiredDates = 'Required Dates',
    CurrentInfo = 'Current Info',
    History = 'History',
    AssignedResource = 'Assigned Resource',
    Other = 'Other'
}

export enum FieldName {
    RequestType = "RequestType",
    AssignedResource = "AssignedResource",
    CrewTypeID = "CrewTypeID",
    RequestedResource = "RequestedResource",
    AssetNumber = "AssetNumber",
    AssetDescription = "AssetDescription",
    AssetCategoryName = "AssetCategoryName",
    Status = "Status",
    Manufacturer = "Manufacturer",
    Model = "Model",
    SerialNumber = "SerialNumber",
    PurchasePrice = "PurchasePrice",
    WarehouseName = "WarehouseName",
    WarehouseID = "WarehouseID",
    PositionTitle = "PositionTitle",
    PhaseDescription = "PhaseDescription",
    UM = "UM",
    AssetCategoryType = "AssetCategoryType",
    CertificationDescription = "CertificationDescription",
    ShortLabel = "ShortLabel",
    User = "User",
    UserFirstName = "UserFirstName",
    UserMiddleName = "UserMiddleName",
    UserLastName = "UserLastName",
    Pronoun = "Pronoun",
    PreferredName = "PreferredName",
    Craft = "Craft",
    Class = "Class",
    CrewTypeDescription = "CrewTypeDescription",
    CrewDescription = "CrewDescription",
    CrewLeaderName = "CrewLeaderName",
    JobStatus = "JobStatus",
    OpportunityStatus = "OpportunityStatus",
    AllocationPercentage = "AllocationPercentage",
    Qty = "Qty",
    BusinessGroupDescription = "BusinessGroupDescription",
    TagDescription = "TagDescription",
    IncludeInForecasting = "IncludeInForecasting",
    Barcode = "Barcode",
    AssetStatus = "AssetStatus",
    JobSitePhase = "JobSitePhase",
    JobSitePhaseID = "JobSitePhaseID",
    LowEnd = "LowEnd",
    HighEnd = "HighEnd",
    TotalHourlyRate = "TotalHourlyRate",
    Address = "Address",
    Address2 = "Address2",
    City = "City",
    State = "State",
    Zip = "Zip",
    UserEmail = "UserEmail",
    WorkEmail = "WorkEmail",
    WorkPhone = "WorkPhone",
    PersonalPhone = "PersonalPhone",
    PurchaseDate = "PurchaseDate",
    WarrantyExpirationDate = "WarrantyExpirationDate",
    BeginDate = "BeginDate",
    ProjectedEndDate = "ProjectedEndDate",
    PurchasedDate = "PurchasedDate",
    SiteName = "SiteName",
    JobsiteID = "JobsiteID",
    StartDate = "StartDate",
    EndDate = "EndDate",
    ResponsiblePerson = "ResponsiblePerson",
    AssetNotes = "AssetNotes",
    Color = "Color",
    Notes = "Notes",
    AssetCategoryID = "AssetCategoryID",
    PositionID = "PositionID",
    TemplateName = "TemplateName",
    PermissionGroupName = "PermissionGroupName",
    EmployeeRequest = "EmployeeRequest",
    AssetRequest = "AssetRequest",
    CrewRequest = "CrewRequest",
    Company = "Company"
}

export enum FieldType {
    Variant = "variant",
    Display = "display",
    Value = "value",
    DisplayAndValue = "display-and-value",
}
