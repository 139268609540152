import { useEffect, useMemo, useState } from "react";
import { ErrorLoadingResource, fetchJson, LoadingState, RemoteResource, timeInMillis } from "../logic/tools";
import { FieldValue } from "../types";
import LRUCache from 'lru-cache'
import { AscendError } from "../logic/AscendError";
import { OperationalError } from "../logic/OperationalError";
import { fixedFieldValuesProvider } from "../components/GridView/FixedFieldValuesProvider";
import { FieldName } from "../interfaces/fields.interface";

const cache = new LRUCache({
    max: 1000,
    ttl: timeInMillis({ minutes: 10 }),
});

const MaxTypeAheadItems = 50;

interface FieldValuesResponse {
    fieldValues: FieldValue[],
    fieldNames: string[],
}

interface FieldValuesApiResponse {
    data: FieldValue[],
    fieldNames: string[],
}

type FieldValuesFilterOptions = { query?: string, filter?: string, filterFieldName?: string };

export function useFieldValues(field: FieldName, { query, filter, filterFieldName }: FieldValuesFilterOptions): RemoteResource<FieldValuesResponse> {
    const options = useMemo(() => ({ query, filter, filterFieldName }), [query, filter, filterFieldName]);
    const [response, setResponse] = useState<RemoteResource<FieldValuesResponse>>(
        { loadingState: LoadingState.Loading, data: undefined });

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchFieldValues() {
            const newResponse = tryLoadFromEnumData(field) ?? await tryLoadFromCacheData(field, options);
            if (newResponse) {
                setResponse(newResponse);
            } else {
                const apiSvcResponse = await loadFromApiService(field, options, abortController.signal);
                setResponse(apiSvcResponse);
            }
        }

        fetchFieldValues().catch((ex: OperationalError) => {
            if (AscendError.isAbortError(ex)) return;
            setResponse({ loadingState: LoadingState.ErrorLoading, error: ex, data: undefined });
        });

        return () => {
            abortController.abort();
        }
    }, [setResponse, field, options]);
    return response;
}

const getCacheKey = (fieldName: FieldName, { query, filter, filterFieldName }: FieldValuesFilterOptions) => {
    if (query) return `${fieldName}_${query??''}`;
    if (filterFieldName) return `${fieldName}_${filterFieldName}_${filter}`;
    return fieldName;
}

function tryLoadFromEnumData(field: string): RemoteResource<FieldValuesResponse> | undefined {
    const fixedValues = fixedFieldValuesProvider.getValuesForField(field);
    if (fixedValues === undefined) {
        return undefined;
    }
    return {
        loadingState: LoadingState.Loaded,
        data: {
            fieldValues: fixedValues.map(x => ({
                value: x.value,
                displayValue: x.label,
                values: [x.value],
            })),
            fieldNames: [field],
        },
    };
}

function tryLoadFromCacheData(field: FieldName, options: FieldValuesFilterOptions
): RemoteResource<FieldValuesResponse> | undefined {
    const cacheKey = getCacheKey(field, options);
    if (!cache.has(cacheKey)) {
        return undefined;
    }
    const cachedValues = cache.get(cacheKey) as Readonly<FieldValuesApiResponse>;
    return {
        loadingState: LoadingState.Loaded,
        data: {
            fieldValues: cachedValues.data,
            fieldNames: cachedValues.fieldNames,
        },
    };
}

async function loadFromApiService(field: FieldName, { query, filter, filterFieldName }: FieldValuesFilterOptions, signal: AbortSignal)
: Promise<RemoteResource<FieldValuesResponse> | ErrorLoadingResource> {
    let isAbortedRequest = false;
    const onAbortedFn = () => { isAbortedRequest = true; }
    signal.addEventListener("abort", onAbortedFn);
    const cacheKey = getCacheKey(field, { query, filter, filterFieldName });
    try {
        let queryParams = '';
        if (filterFieldName) {
            queryParams = `?filter=${encodeURIComponent(filter??'')}`
                + `&filterFieldName=${encodeURIComponent(filterFieldName)}`;
        } else if (query) {
            queryParams = `?query=${encodeURIComponent(query)}&limit=${MaxTypeAheadItems}`;
        }
        const url = `/api/fields/${encodeURIComponent(field)}/values${queryParams}`;
        const responsePromise = fetchJson<FieldValuesApiResponse>(
            url, "useFieldTypeAheadValues", `field values for ${field}`, signal
        );
        cache.set(cacheKey, responsePromise);
        const response = await responsePromise;
        // Aborted requests should not update state used in the UI, but it can still cache the value from the request
        cache.set(cacheKey, response);
        if (isAbortedRequest) {
            return { loadingState: LoadingState.Loading, data: undefined };
        }
        return {
            loadingState: LoadingState.Loaded,
            data: {
                fieldValues: response.data,
                fieldNames: response.fieldNames,
            },
        };
    } catch (ex) {
        cache.delete(cacheKey);
        return {
            loadingState: LoadingState.ErrorLoading,
            error: ex as OperationalError,
            data: undefined,
        }
    } finally {
        signal.removeEventListener("abort", onAbortedFn);
    }
}
