import { registerLicense } from '@syncfusion/ej2-base';
import React, { useEffect, useMemo } from 'react';
import { BrowserRouter, Route, Routes, redirect, ActionFunctionArgs } from "react-router-dom";
import ThemeProvider from './components/themes/ThemeProvider';
import AppLayout from './pages/AppLayout';
import { PageMeta, rootPage } from './hooks/usePageMeta';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { isDevMode } from './logic/tools';
import './components/themes/SyncfusionStyles.css';
import { MsalProvider } from "@azure/msal-react";
import { AccountInfo, EventPayload, EventType, IdTokenClaims, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies, msalConfig } from './authConfig';
import { v4 as uuidv4 } from 'uuid'; // Import uuid v4
import { compareIssuingPolicy } from './utils/claimUtils';
// Configure MSAL to use uuidv4 for generating unique identifiers
(window.crypto as any).randomUUID = () => uuidv4();

registerLicense('ORg4AjUWIQA/Gnt2VVhkQlFadVdJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkRiW31cdHZWTmFcU0U=');

function App() {
  const routes = getRoutes();
  
  useEffect(() => {
    if (isDevMode()) {
      const sourceMapScriptId = 'source-map-script';
      const sourceMapScript = document.createElement('script') as HTMLScriptElement;
      sourceMapScript.id = sourceMapScriptId;
      // file copied from /node_modules/sourcemapped-stacktrace/dist/sourcemapped-stacktrace.js
      sourceMapScript.src = '/sourcemapped-stacktrace.js';
      document.head.appendChild(sourceMapScript);
    }
  }, []);

  const msalInstance = useMemo(() => { return new PublicClientApplication(msalConfig)}, []);
  useEffect(() => {
    const callbackId = msalInstance.addEventCallback((event) => {
        let payload = event.payload as unknown as EventPayload;
        let account = payload as AccountInfo;
        if (
            (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) && account !== null) {
            /**
             * For the purpose of setting an active account for UI update, we want to consider only the auth
             * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
             * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
             * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
             */
            if (compareIssuingPolicy(account.idTokenClaims as IdTokenClaims, b2cPolicies.names.editProfile)) {
                // retrieve the account from initial sing-in to the app
                const originalSignInAccount = msalInstance
                    .getAllAccounts()
                    .find(
                        (acc) =>
                            acc.idTokenClaims?.oid === account.idTokenClaims?.oid &&
                            acc.idTokenClaims?.sub === account.idTokenClaims?.sub && 
                            compareIssuingPolicy(acc.idTokenClaims as IdTokenClaims, b2cPolicies.names.signUpSignIn)        
                    );

                let signUpSignInFlowRequest = {
                    authority: b2cPolicies.authorities.signUpSignIn.authority,
                    account: originalSignInAccount,
                };

                // silently login again with the signUpSignIn policy
                msalInstance.ssoSilent(signUpSignInFlowRequest);
            }

            /**
             * Below we are checking if the user is returning from the reset password flow.
             * If so, we will ask the user to reauthenticate with their new password.
             * If you do not want this behavior and prefer your users to stay signed in instead,
             * you can replace the code below with the same pattern used for handling the return from
             * profile edit flow
             */
            if (compareIssuingPolicy(account.idTokenClaims as IdTokenClaims, b2cPolicies.names.forgotPassword)) {
                msalInstance.loginRedirect(b2cPolicies.authorities.editProfile as unknown as RedirectRequest);
            }
        }

        if (event.eventType === EventType.LOGIN_FAILURE) {
            // Check for forgot password error
            // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
            if (event.error && event.error.message.includes('AADB2C90118')) {
                const resetPasswordRequest = {
                    authority: b2cPolicies.authorities.forgotPassword.authority,
                    scopes: [],
                };
                msalInstance.loginRedirect(resetPasswordRequest);
            }
        }
    });

    return () => {
        if (callbackId) {
          msalInstance.removeEventCallback(callbackId);
        }
    };
  }, [msalInstance]);

  return (
    <MsalProvider instance={msalInstance}>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            {routes}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </MsalProvider>
  );
}

function getRoutes(): React.ReactNode {
  const routes: React.ReactNode[] = [];
  function getRoutesRecursively(page: PageMeta) {
    if (page.element) {
      routes.push(<Route key={page.fullPath} path={page.fullPath} element={page.element} />);
    }
    page.subPages?.forEach(x => getRoutesRecursively(x));
  }
  getRoutesRecursively(rootPage);
  return (
    <Route element={<AppLayout />}>
      <Route path=".*/$" id='RedirectWithoutTrailingSlash' action={(props:ActionFunctionArgs) => redirect(props.request.url.replace(/\/+$/, '')) } />
      {routes}
      <Route path="*" element={<PageNotFound />} />
    </Route>
  );
}

function PageNotFound() {
  return <h1>Page not found</h1>
}

export default App;
