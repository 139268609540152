import React, { useEffect } from "react";
import useLocalStorage from "use-local-storage";

export enum Theme {
    light = 'light',
    dark = 'dark',
}

export default function ThemeProvider({ children }: { children:React.ReactNode }) {
    const [localStorageTheme] = useLocalStorage("theme", Theme.dark);
    useEffect(() => {
        setThemeLinksInDom(localStorageTheme);
    }, [localStorageTheme]);
    return <>{children}</>
}

export function useTheme():  [Theme, (theme:Theme) =>  void] {
    const [localStorageTheme, setLocalStorageTheme] = useLocalStorage("theme", Theme.dark);
    const setTheme = (theme:Theme) => {
        setLocalStorageTheme(theme);
        setThemeLinksInDom(theme);
    };
    return [localStorageTheme,  setTheme];
}

function setThemeLinksInDom(theme:Theme) {
    const themeHref = `/${theme}-theme.css`;
    const syncFusionHref = theme ===  'light' ? '/material.min.css' : '/material-dark.min.css';
    setThemeLinkWithHrefInDom('theme', themeHref);
    setThemeLinkWithHrefInDom('sync-fusion-theme', syncFusionHref);
}

function setThemeLinkWithHrefInDom(id:string, href:string) {
    let themeLink = document.querySelector(`#${id}`) as HTMLLinkElement;
    if (themeLink) {
        themeLink.href = href;
    } else {
        themeLink = document.createElement('link') as HTMLLinkElement;
        themeLink.id = id;
        themeLink.type = 'text/css';
        themeLink.rel = 'stylesheet';
        themeLink.href = href;
        document.head.appendChild(themeLink);
    }
}
